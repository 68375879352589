import { type AppType } from 'next/app';
import { api } from '~/utils/api';
import '~/styles/globals.css';

import Router from 'next/router';
import { useState, useEffect } from 'react';
import ProgressLoader from '../components/ProgressLoader';
import Layout from '../components/Layout';

//Fontawesome setup
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;

const PatientPortalApp: AppType = ({ Component, pageProps }) => {
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		Router.events.on('routeChangeStart', () => {
			setIsLoading(true);
		});

		Router.events.on('routeChangeComplete', () => {
			setIsLoading(false);
		});

		Router.events.on('routeChangeError', () => {
			setIsLoading(false);
		});
	});

	return (
		<>
			{isLoading && (
				<Layout>
					<ProgressLoader />
				</Layout>
			)}
			<Component {...pageProps} />
		</>
	);
};

export default api.withTRPC(PatientPortalApp);
