/**
 * This is the client-side entrypoint for your tRPC API. It is used to create the `api` object which
 * contains the Next.js App-wrapper, as well as your type-safe React Query hooks.
 *
 * We also create a few inference helpers for input and output types.
 */
import { httpBatchLink, loggerLink } from '@trpc/client';
import { createTRPCNext } from '@trpc/next';
import { type inferRouterInputs, type inferRouterOutputs } from '@trpc/server';
import superjson from 'superjson';
import { TRPCClientError } from '@trpc/client';
import { type AppRouter } from '~/server/api/root';
import patientPortalStore from '../store/patientPortal';
import Router from 'next/router';

const handleUnauthorizedErrorsOnClient = (error: unknown): boolean => {
	if (typeof window === 'undefined') return false;
	if (error instanceof TRPCClientError && error.data === undefined) {
		return false;
	}
	if (
		error instanceof TRPCClientError &&
		error.data &&
		typeof error.data === 'object' &&
		(error.data as { code?: unknown }).code &&
		typeof (error.data as { code?: unknown }).code === 'string' &&
		(error.data as { code?: string }).code !== 'UNAUTHORIZED'
	) {
		return false;
	}

	patientPortalStore.persist.clearStorage();
	void Router.push('/');

	return true;
};

const getBaseUrl = () => {
	if (typeof window !== 'undefined') return ''; // browser should use relative url
	if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`; // SSR should use vercel url
	return `http://localhost:${process.env.PORT ?? 3000}`; // dev SSR should use localhost
};

/** A set of type-safe react-query hooks for your tRPC API. */
export const api = createTRPCNext<AppRouter>({
	config() {
		return {
			/**
			 * Transformer used for data de-serialization from the server.
			 *
			 * @see https://trpc.io/docs/data-transformers
			 */
			//abortOnUnmount: true,
			transformer: superjson,
			queryClientConfig: {
				defaultOptions: {
					queries: {
						retry: (failureCount, error) => {
							if (handleUnauthorizedErrorsOnClient(error)) return false;
							return failureCount < 3;
						},
					},
					mutations: {
						retry: (_, error) => {
							handleUnauthorizedErrorsOnClient(error);
							return false;
						},
					},
				},
			},

			/**
			 * Links used to determine request flow from client to server.
			 *
			 * @see https://trpc.io/docs/links
			 */
			links: [
				loggerLink({
					enabled: (opts) =>
						process.env.NODE_ENV === 'development' ||
						(opts.direction === 'down' && opts.result instanceof Error),
				}),
				httpBatchLink({
					headers() {
						// If we have a JWT, send it with the request
						const auth = patientPortalStore.getState().auth;
						if (auth && auth.accessToken !== '') {
							return {
								authorization: `${auth.tokenType} ${auth.accessToken}`,
							};
						}
						// Default to no headers
						return {};
					},
					url: `${getBaseUrl()}/api/trpc`,
				}),
			],
		};
	},
	/**
	 * Whether tRPC should await queries when server rendering pages.
	 *
	 * @see https://trpc.io/docs/nextjs#ssr-boolean-default-false
	 */
	ssr: false,
});

/**
 * Inference helper for inputs.
 *
 * @example type HelloInput = RouterInputs['example']['hello']
 */
export type RouterInputs = inferRouterInputs<AppRouter>;

/**
 * Inference helper for outputs.
 *
 * @example type HelloOutput = RouterOutputs['example']['hello']
 */
export type RouterOutputs = inferRouterOutputs<AppRouter>;
