var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1.3.14272"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { CaptureConsole } from '@sentry/integrations';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
	dsn:
		SENTRY_DSN ||
		'https://3c9d7dae6ec44dd095b00ad2e1514274@o412868.ingest.sentry.io/4504327406354432',
	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 1.0,
	environment: process.env.SENTRY_APP_ENV,
	enabled: process.env.SENTRY_APP_ENV !== 'development',
	integrations: [
		new CaptureConsole({
			levels: ['error', 'warn'],
		}),
		new Sentry.Replay(),
	],
	ignoreErrors: ['%c'],
	beforeBreadcrumb(breadcrumb) {
		return breadcrumb.message?.startsWith('%c') ? null : breadcrumb;
	},
	beforeSend(event) {
		// Ignore TRPClientError since we already log them through console.error
		if (event.exception?.values?.[0]?.type === 'TRPCClientError') {
			return null;
		}
		return event;
	},
	replaysSessionSampleRate: 0,
	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 0,
	// ...
	// Note: if you want to override the automatic release value, do not set a
	// `release` value here - use the environment variable `SENTRY_RELEASE`, so
	// that it will also get attached to your source maps
});
