import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const env = createEnv({
	/**
	 * Specify your server-side environment variables schema here. This way you can ensure the app
	 * isn't built with invalid env vars.
	 */
	server: {
		NODE_ENV: z.enum(['development', 'test', 'production']),
		INNOVDR_API_BACKEND: z.string(),
		INNOVDR_BANKID_API_KEY: z.string(),
		TIME_API_CALLS: z.string().optional(),
		APP_ENV: z.enum(['development', 'test', 'production']).optional(),
		HOST: z.string().optional(),
	},

	/**
	 * Specify your client-side environment variables schema here. This way you can ensure the app
	 * isn't built with invalid env vars. To expose them to the client, prefix them with
	 * `NEXT_PUBLIC_`.
	 */
	client: {
		// NEXT_PUBLIC_CLIENTVAR: z.string().min(1),
		NEXT_PUBLIC_APP_ENV: z.enum(['development', 'test', 'production']).optional(),
		NEXT_PUBLIC_MARKET: z.string().optional(),
	},

	/**
	 * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
	 * middlewares) or client-side so we need to destruct manually.
	 */
	runtimeEnv: {
		NODE_ENV: process.env.NODE_ENV,
		INNOVDR_API_BACKEND: process.env.INNOVDR_API_BACKEND,
		INNOVDR_BANKID_API_KEY: process.env.INNOVDR_BANKID_API_KEY,
		TIME_API_CALLS: process.env.TIME_API_CALLS,
		APP_ENV: process.env.APP_ENV,
		NEXT_PUBLIC_APP_ENV: process.env.NEXT_PUBLIC_APP_ENV ?? process.env.APP_ENV,
		NEXT_PUBLIC_MARKET: process.env.NEXT_PUBLIC_MARKET,
		HOST: process.env.HOST ?? undefined,

		// NEXT_PUBLIC_CLIENTVAR: process.env.NEXT_PUBLIC_CLIENTVAR,
	},
	/**
	 * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation.
	 * This is especially useful for Docker builds.
	 */
	skipValidation: !!process.env.SKIP_ENV_VALIDATION,
});
