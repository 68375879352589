import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { env } from '~/env.mjs';
import { getLocale, type Country, type Locale } from '~/utils/marketResolver';

type AppStoreProps = {
	activeUser: string | undefined;
	userLoggedIn: boolean;
	auth: { accessToken: string; tokenType: string; expiresInMinutes: number } | undefined;
	loginAuth: { accessToken: string; tokenType: string; expiresInMinutes: number } | undefined;
	country: Country;
	locale: Locale;
};

type AppStoreFn = {
	setActiveUser: (id: string) => void;
	setUserLoggedIn: () => void;
	setJWT: (auth: { accessToken: string; tokenType: string; expiresInMinutes: number }) => void;
	setLoginJWT: (
		auth:
			| {
					accessToken: string;
					tokenType: string;
					expiresInMinutes: number;
			  }
			| undefined
	) => void;
	clear: () => void;
	logout: () => void;
	setMarket: (marketUpdate: { country: Country; locale: Locale }) => void;
};

const initialState: AppStoreProps = {
	userLoggedIn: false,
	activeUser: undefined,
	auth: undefined,
	loginAuth: undefined,
	country: (env.NEXT_PUBLIC_MARKET as Country) ?? 'SE',
	locale: env.NEXT_PUBLIC_MARKET ? getLocale(undefined) : 'sv_SE',
};

export type PatientPortalStore = AppStoreProps & AppStoreFn;

const patientPortalStore = create<PatientPortalStore>()(
	persist(
		(set) => ({
			...initialState,
			setJWT: (auth: {
				accessToken: string;
				tokenType: string;
				expiresInMinutes: number;
			}) => {
				set({ auth, loginAuth: undefined });
			},
			setLoginJWT: (
				auth:
					| {
							accessToken: string;
							tokenType: string;
							expiresInMinutes: number;
					  }
					| undefined
			) => {
				set({ loginAuth: auth });
			},
			setUserLoggedIn() {
				set({ userLoggedIn: true });
			},
			setActiveUser(id: string) {
				set({ activeUser: id });
			},
			clear: () => set(initialState),
			logout: () => {
				set({ ...initialState });
			},
			setMarket: ({ country, locale }) => {
				set({ country, locale });
			},
		}),
		{
			name: 'store',
			storage: createJSONStorage(() => sessionStorage),
			partialize(state) {
				return {
					auth: state.auth,
					activeUser: state.activeUser,
					loginAuth: state.loginAuth,
					country: state.country,
					locale: state.locale,
					userLoggedIn: state.userLoggedIn,
				};
			},
		}
	)
);

export default patientPortalStore;
