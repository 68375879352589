import { useEffect, useState } from 'react';

const Layout = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
	const [isHydrated, setIsHydrated] = useState(false);

	useEffect(() => {
		setIsHydrated(true);
	}, []);

	return (
		<>
			{isHydrated ? (
				<main className="relative m-0 flex h-screen flex-col items-center justify-center bg-neutral-50 p-1">
					{children}
				</main>
			) : (
				<main className="relative m-0 flex min-h-[85vh] flex-col items-center justify-center bg-slate-50 p-1"></main>
			)}
		</>
	);
};

export default Layout;
