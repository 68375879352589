import { env } from '~/env.mjs';

export type Country = 'FI' | 'SE';
export type Locale = 'sv_SE' | 'fi_FI';

const KOLLARNA_SE = 'patient.kollarna.se';
const KOLLARNA_FI = 'potilas.oireapu.fi';

const createLocale = (country: Country): Locale => {
	switch (country) {
		case 'FI':
			return 'fi_FI';
		case 'SE':
			return 'sv_SE';
		default:
			return 'sv_SE';
	}
};

export const getCountry = (domain: string | undefined): Country => {
	switch (domain) {
		case KOLLARNA_SE:
			return 'SE';
		case KOLLARNA_FI:
			return 'FI';
		default:
			return (env.NEXT_PUBLIC_MARKET as Country) ?? 'SE';
	}
};

export const getLocale = (locale: string | undefined): Locale => {
	switch (locale) {
		case KOLLARNA_SE:
			return 'sv_SE';
		case KOLLARNA_FI:
			return 'fi_FI';
		default:
			return env.NEXT_PUBLIC_MARKET
				? createLocale(env.NEXT_PUBLIC_MARKET as Country)
				: 'sv_SE';
	}
};

export const getCountryLogo = (country?: Country): string => {
	switch (country) {
		case 'FI':
			return '/logos/uti_logo_fi.svg';
		case 'SE':
			return '/kollarna_logo.svg';
		default:
			return '/kollarna_logo.svg';
	}
};
